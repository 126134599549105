import {SET_FULFILLMENT_CHANGE_SUCCESSFULLY_SUBMITTED, UPDATE_CART_LOCATION_SUCCESS} from '../../../action-types';
import {createReducer} from '../../reducer-factory';
import updateObject from '../../handler-functions/update-object';

const handlers = {
    [SET_FULFILLMENT_CHANGE_SUCCESSFULLY_SUBMITTED]: updateObject('successfullySubmitted'),
    [UPDATE_CART_LOCATION_SUCCESS]: updateObject('successfullySubmitted')
};

export default createReducer(false, handlers);
