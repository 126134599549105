import {combineReducers} from 'redux';

import citrusBannerUrl from './citrus-banner-url-reducer';

const reducers = () =>
    combineReducers({
        citrusBannerUrl
    });

export default reducers;
