import {useEffect} from 'react';
import {useQuery} from '@apollo/client';
import {useSelector} from 'react-redux';

import {
    GetBasicCustomerInformation,
    GetBasicCustomerInformationVariables,
    GetBasicCustomerInformation_carts
} from 'autogen/GetBasicCustomerInformation';
import {getBasicCustomerInformation} from 'client/graphql/queries/cart-queries';

import {getBasketContinuitySummaryDrawer} from '../services/basket-continuity-service';

export const useInactiveCartItemCheck = () => {
    const customer = useSelector((state: any) => state.customer);

    const {data} = useQuery<GetBasicCustomerInformation, GetBasicCustomerInformationVariables>(
        getBasicCustomerInformation,
        {
            fetchPolicy: 'cache-only',
            skip: !customer?.customerId,
            variables: {
                customerId: Number(customer?.customerId),
                customerUuid: customer?.customerUuid?.toLowerCase(),
                includeProductCardData: true
            }
        }
    );

    const inactiveCartItemCheck = async (carts: GetBasicCustomerInformation_carts[]) => {
        const cart = carts[0];

        const {cartId, customerId, deliveryAddressId, fulfillmentLocation, fulfillmentType, pickupLocation, storeId} =
            cart;

        if (fulfillmentLocation) {
            await getBasketContinuitySummaryDrawer({
                cartId,
                customerId,
                deliveryAddressId,
                fulfillmentLocationId: Number(fulfillmentLocation?.fulfillmentLocationId),
                fulfillmentType,
                isInactiveCartItemCheck: true,
                keepOpen: true,
                pickupLocationId: Number(pickupLocation?.pickupLocationId),
                storeId: Number(storeId)
            });
        }
    };

    useEffect(() => {
        if (customer && data?.carts?.length) {
            inactiveCartItemCheck(data.carts);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.customer?.customerUuid]);
};
