import {combineReducers} from 'redux';

import addCardError from './add-card-error-reducer';
import billingPhone from './billing-phone-reducer';
import paymentMethods from './payment-methods-reducer';
import giftCardError from './gift-card-error-reducer';
import loadGiftCardsError from './gift-cards-error-reducer';
import giftCardTotal from './gift-card-total-reducer';
import giftCards from './gift-cards-reducer';
import selectedCustomerCardId from './selected-customer-card-reducer';
import deleteCardError from './delete-card-error-reducer';
import paymentCheckoutView from './payment-checkout-view-reducer';
import newCustomerCardId from './new-customer-card-reducer';
import shouldApplyGiftCards from './should-apply-gift-cards-reducer';
import loadCustomerCardsError from './customer-cards-error-reducer';
import taxExemption from './tax-exemption-reducer';

const reducers = () =>
    combineReducers({
        addCardError,
        billingPhone,
        deleteCardError,
        giftCardError,
        giftCards,
        giftCardTotal,
        loadCustomerCardsError,
        loadGiftCardsError,
        newCustomerCardId,
        paymentCheckoutView,
        paymentMethods,
        selectedCustomerCardId,
        shouldApplyGiftCards,
        taxExemption
    });

export default reducers;
