import {createReducer} from '../reducer-factory';
import updateObject from '../handler-functions/update-object';
import {
    SET_FULFILLMENT_CHANGE_PAGE_DATA_SUCCESS,
    RESET_FULFILLMENT_CHANGE_REMOVED_CART_ITEMS
} from '../../action-types';

const handlers = {
    [RESET_FULFILLMENT_CHANGE_REMOVED_CART_ITEMS]: updateObject('removedCartItems'),
    [SET_FULFILLMENT_CHANGE_PAGE_DATA_SUCCESS]: updateObject('removedCartItems')
};

export default createReducer([], handlers);
