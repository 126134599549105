import {differenceInCalendarDays, addDays, parseISO, isBefore} from 'date-fns';

import {createReducer} from '../reducer-factory';
import updateObject from '../handler-functions/update-object';
import {SET_SELECTED_TIME_RESERVATION_TAB, SET_TIME_RESERVATION_PAGE_DATA_SUCCESS} from '../../action-types';

const selectInitialTimeReservationTab = (state, action) => {
    const timeReservationDays = [...new Array(7)].map((tab, index) => addDays(new Date(), index));

    if (!action.ignoreCartTimeSlot && isBefore(new Date(), parseISO(action.cart.fulfillmentExpiration))) {
        return timeReservationDays.findIndex(
            (day) => differenceInCalendarDays(day, parseISO(action.cart.fulfillmentWindowStart)) === 0
        );
    }

    return timeReservationDays.findIndex((day) =>
        action.fulfillmentTimes.find(
            (fulfillmentTime) =>
                differenceInCalendarDays(day, parseISO(fulfillmentTime.windowStart)) === 0 &&
                fulfillmentTime.isAvailable
        )
    );
};

const handlers = {
    [SET_SELECTED_TIME_RESERVATION_TAB]: updateObject('selectedTimeReservationTab'),
    [SET_TIME_RESERVATION_PAGE_DATA_SUCCESS]: selectInitialTimeReservationTab
};

export default createReducer(0, handlers);
