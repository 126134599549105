import {SET_SELECTED_HIERARCHY} from '../../action-types';
import {createReducer} from '../reducer-factory';
import updateObject from '../handler-functions/update-object';

const loadHierarchyIntoState = updateObject('hierarchy');

const handlers = {
    [SET_SELECTED_HIERARCHY]: loadHierarchyIntoState
};

export default createReducer({}, handlers);
