import {useSelector} from 'react-redux';

import {waitForDataLayer} from '../../../services/analytics-service';

const addUserContextToGTM = (isAuthenticated, customerUuid) =>
    waitForDataLayer(() => {
        const checks = [
            typeof global.dataLayer !== 'undefined',
            typeof customerUuid !== 'undefined',
            Array.isArray(global.dataLayer),
            isAuthenticated === true
        ];

        if (checks.every(Boolean)) {
            global.dataLayer.push({userId: customerUuid});
        }
    });

const GoogleTagManagerWrapper = ({children}) => {
    const isAuthenticated = useSelector((state) => state.isAuthenticated);
    const customerUuid = useSelector((state) => state?.customer?.customerUuid);

    addUserContextToGTM(isAuthenticated, customerUuid);

    return children;
};

export default GoogleTagManagerWrapper;
