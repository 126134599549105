import {combineReducers} from 'redux';

import checkoutId from './checkout-reducer';
import paypalError from './error-reducer';

const reducers = () =>
    combineReducers({
        checkoutId,
        paypalError
    });

export default reducers;
