import {addDays, differenceInCalendarDays, parseISO} from 'date-fns';

import {createReducer} from '../reducer-factory';
import {SET_TIME_RESERVATION_PAGE_DATA_SUCCESS} from '../../action-types';

const setTimeReservationDays = (state, action) =>
    [...new Array(7)].map((tab, i) => {
        const date = addDays(new Date(Date.now()), i);
        const timesOnSelectedDay = action.fulfillmentTimes.filter(
            (fulfillmentTime) =>
                differenceInCalendarDays(date, parseISO(fulfillmentTime.windowStart)) === 0 &&
                fulfillmentTime.isAvailable
        );

        return {
            date: date.toISOString(),
            durations: [...new Set(timesOnSelectedDay.map((day) => day.duration))].sort(),
            isAvailable: timesOnSelectedDay.length > 0
        };
    });

const handlers = {
    [SET_TIME_RESERVATION_PAGE_DATA_SUCCESS]: setTimeReservationDays
};

export default createReducer([], handlers);
