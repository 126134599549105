import {SET_DELETE_CARD_ERROR, DELETE_CARD_SUCCESS, CLEAR_DELETE_CARD_ERROR} from '../../action-types';
import {createReducer} from '../reducer-factory';
import updateObject from '../handler-functions/update-object';

const removeErrors = () => ({});

const handlers = {
    [CLEAR_DELETE_CARD_ERROR]: removeErrors,
    [DELETE_CARD_SUCCESS]: removeErrors,
    [SET_DELETE_CARD_ERROR]: updateObject('deleteCardError')
};

export default createReducer({}, handlers);
