import {
    CLEAR_SAVE_ADDRESS_ERROR_STATUS,
    SET_ADDRESS_ERROR_STATUS,
    SET_ADDRESS_SUGGESTIONS_FAILURE,
    SET_SAVE_NEW_DELIVERY_ADDRESS_FAILURE,
    SET_STORE_SELECTION_DELIVERY_PAGE_DATA_SUCCESS,
    SET_STORE_SELECTION_TYPE_TAB
} from '../../action-types';
import {createReducer} from '../reducer-factory';
import updateObject from '../handler-functions/update-object';

const setErrorForNoDeliverableAddresses = (state, action) => {
    if (action.noEligibleAddresses) {
        return 'None of your saved addresses are eligible for delivery. Please enter a new address.';
    }

    return state;
};

const handlers = {
    [CLEAR_SAVE_ADDRESS_ERROR_STATUS]: updateObject('saveAddressErrorStatus'),
    [SET_ADDRESS_ERROR_STATUS]: updateObject('saveAddressErrorStatus'),
    [SET_ADDRESS_SUGGESTIONS_FAILURE]: updateObject('saveAddressErrorStatus'),
    [SET_SAVE_NEW_DELIVERY_ADDRESS_FAILURE]: updateObject('saveAddressErrorStatus'),
    [SET_STORE_SELECTION_DELIVERY_PAGE_DATA_SUCCESS]: setErrorForNoDeliverableAddresses,
    [SET_STORE_SELECTION_TYPE_TAB]: updateObject('saveAddressErrorStatus')
};

export default createReducer(null, handlers);
