import {PLACE_ORDER_FAILURE, CLEAR_PLACE_ORDER_ERROR_STATUS} from '../action-types';

import {createReducer} from './reducer-factory';
import updateObject from './handler-functions/update-object';

const handlers = {
    [CLEAR_PLACE_ORDER_ERROR_STATUS]: updateObject('placeOrderErrorStatus'),
    [PLACE_ORDER_FAILURE]: updateObject('placeOrderErrorStatus')
};

export default createReducer(null, handlers);
