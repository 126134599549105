import {isBefore, parseISO, isSameDay} from 'date-fns';

import {createReducer} from '../reducer-factory';
import updateObject from '../handler-functions/update-object';
import {SET_SELECTED_TIME_DURATION_TAB, SET_TIME_RESERVATION_PAGE_DATA_SUCCESS} from '../../action-types';
import {isFulfillmentTimeSelected} from '../../utils/view-helpers/fulfillment-view-helpers';

const selectInitialTimeDurationTab = (state, action) => {
    const defaultDurationTabIndex = 0;

    if (isBefore(new Date(), parseISO(action.cart.fulfillmentExpiration))) {
        const selectedFulfillmentTime = action.fulfillmentTimes.find((fulfillmentTime) =>
            isFulfillmentTimeSelected(fulfillmentTime, action.cart)
        );

        if (!selectedFulfillmentTime) {
            return defaultDurationTabIndex;
        }

        const timesOnSelectedDay = action.fulfillmentTimes.filter(
            (fulfillmentTime) =>
                isSameDay(parseISO(fulfillmentTime.windowStart), parseISO(selectedFulfillmentTime.windowStart)) &&
                fulfillmentTime.isAvailable
        );

        const durations = [...new Set(timesOnSelectedDay.map((day) => day.duration))].sort();

        return durations.findIndex((duration) => duration === selectedFulfillmentTime.duration);
    }

    return defaultDurationTabIndex;
};

const handlers = {
    [SET_SELECTED_TIME_DURATION_TAB]: updateObject('selectedTimeDurationTab'),
    [SET_TIME_RESERVATION_PAGE_DATA_SUCCESS]: selectInitialTimeDurationTab
};

export default createReducer(0, handlers);
