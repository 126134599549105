import {
    SET_FULFILLMENT_CHANGE_PAGE_DATA_FAILURE,
    SET_FULFILLMENT_CHANGE_PAGE_DATA_REQUEST,
    SET_FULFILLMENT_CHANGE_PAGE_DATA_SUCCESS,
    UPDATE_CART_LOCATION_FAILURE,
    UPDATE_CART_LOCATION_REQUEST,
    UPDATE_CART_LOCATION_SUCCESS
} from '../../action-types';

import {startLoadingIndicator, stopLoadingIndicator} from './helpers/loading-functions';

const changeLocationLoadingIndicators = {
    [UPDATE_CART_LOCATION_FAILURE]: stopLoadingIndicator(UPDATE_CART_LOCATION_REQUEST),
    [UPDATE_CART_LOCATION_REQUEST]: startLoadingIndicator,
    [UPDATE_CART_LOCATION_SUCCESS]: stopLoadingIndicator(UPDATE_CART_LOCATION_REQUEST)
};

const fulfillmentChangePageDataLoadingIndicators = {
    [SET_FULFILLMENT_CHANGE_PAGE_DATA_FAILURE]: stopLoadingIndicator(SET_FULFILLMENT_CHANGE_PAGE_DATA_REQUEST),
    [SET_FULFILLMENT_CHANGE_PAGE_DATA_REQUEST]: startLoadingIndicator,
    [SET_FULFILLMENT_CHANGE_PAGE_DATA_SUCCESS]: stopLoadingIndicator(SET_FULFILLMENT_CHANGE_PAGE_DATA_REQUEST)
};

const fulfillmentChangeLoadingIndicators = {
    ...changeLocationLoadingIndicators,
    ...fulfillmentChangePageDataLoadingIndicators
};

export default fulfillmentChangeLoadingIndicators;
