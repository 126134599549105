import {SET_TIME_RESERVATION_PAGE_DATA_SUCCESS} from '../action-types';

import {createReducer} from './reducer-factory';
import updateObject from './handler-functions/update-object';

const handlers = {
    [SET_TIME_RESERVATION_PAGE_DATA_SUCCESS]: updateObject('fulfillmentLocation')
};

export default createReducer({}, handlers);
