import styled from 'styled-components';
import Alert from '@hy-vee/web-core/lib/components/alert';

import AlertStatusType from '../../enums/alert-status-types';

import FocusedContent from './focused-content';

const StyledAlertContainer = styled.div`
    margin-top: ${(props) => (props.pageContext ? '50px' : '0')}
    width: 100%;
`;

const AlertMessageBanner = ({children, pageContext}) => (
    <FocusedContent maxwidth={pageContext ? '600px' : 'none'}>
        <StyledAlertContainer pageContext={pageContext}>
            <Alert status={AlertStatusType.ERROR_ALERT}>{children}</Alert>
        </StyledAlertContainer>
    </FocusedContent>
);

export default AlertMessageBanner;
