import {SET_FULFILLMENT_CHANGE_PAGE_DATA_SUCCESS, SET_TIME_RESERVATION_PAGE_DATA_SUCCESS} from '../action-types';

import {createReducer} from './reducer-factory';

const indexProductsByProductId = (state, action) => {
    const newProducts = {};

    action.products.forEach((product) => {
        newProducts[product.productId] = product;
    });

    return {
        ...state,
        ...newProducts
    };
};

const handlers = {
    [SET_FULFILLMENT_CHANGE_PAGE_DATA_SUCCESS]: indexProductsByProductId,
    [SET_TIME_RESERVATION_PAGE_DATA_SUCCESS]: indexProductsByProductId
};

export default createReducer({}, handlers);
