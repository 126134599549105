import {
    SET_SHOULD_DISPLAY_NEW_ADDRESS_FORM,
    SET_STORE_SELECTION_DELIVERY_PAGE_DATA_SUCCESS,
    SET_STORE_SELECTION_TYPE_TAB
} from '../../action-types';
import {createReducer} from '../reducer-factory';
import updateObject from '../handler-functions/update-object';

const handlers = {
    [SET_SHOULD_DISPLAY_NEW_ADDRESS_FORM]: updateObject('shouldDisplayNewAddressForm'),
    [SET_STORE_SELECTION_DELIVERY_PAGE_DATA_SUCCESS]: updateObject('shouldDisplayNewAddressForm'),
    [SET_STORE_SELECTION_TYPE_TAB]: updateObject('shouldDisplayNewAddressForm')
};

export default createReducer(true, handlers);
