import {SET_PASSCODE_VALIDATION_ERROR_MESSAGE, UPDATE_CART_LOCATION_FAILURE} from '../../../../../action-types';
import {createReducer} from '../../../../reducer-factory';
import updateObject from '../../../../handler-functions/update-object';

const handlers = {
    [SET_PASSCODE_VALIDATION_ERROR_MESSAGE]: updateObject('validationErrorMessage'),
    [UPDATE_CART_LOCATION_FAILURE]: updateObject('validationErrorMessage')
};

export default createReducer('', handlers);
