import {SET_FULFILLMENT_TIME_ERROR, SET_TIME_RESERVATION_PAGE_DATA_SUCCESS} from '../../action-types';
import {createReducer} from '../reducer-factory';

const addError = (state, action) => {
    const updatedState = {...state};

    updatedState[action.fulfillmentTimeId] = action.errorType;

    return updatedState;
};

const removeErrors = () => ({});

const handlers = {
    [SET_FULFILLMENT_TIME_ERROR]: addError,
    [SET_TIME_RESERVATION_PAGE_DATA_SUCCESS]: removeErrors
};

export default createReducer({}, handlers);
