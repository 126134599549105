import {SET_FULFILLMENT_CHANGE_PAGE_DATA_SUCCESS, SET_TIME_RESERVATION_PAGE_DATA_SUCCESS} from '../action-types';

import {createReducer} from './reducer-factory';

const indexStoreProductsByStoreProductId = (state, action) => {
    const newStoreProducts = {};

    action.storeProducts.forEach((storeProduct) => {
        newStoreProducts[storeProduct.storeProductId] = storeProduct;
    });

    return {
        ...state,
        ...newStoreProducts
    };
};

const handlers = {
    [SET_FULFILLMENT_CHANGE_PAGE_DATA_SUCCESS]: indexStoreProductsByStoreProductId,
    [SET_TIME_RESERVATION_PAGE_DATA_SUCCESS]: indexStoreProductsByStoreProductId
};

export default createReducer({}, handlers);
