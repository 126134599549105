import {SET_MTO_ORDER_VALIDATED} from '../action-types';

import {createReducer} from './reducer-factory';
import updateObject from './handler-functions/update-object';

const handlers = {
    [SET_MTO_ORDER_VALIDATED]: updateObject('mtoOrderValidated')
};

export default createReducer(false, handlers);
