import {
    SET_FULFILLMENT_CHANGE_PAGE_DATA_SUCCESS,
    SET_TIME_RESERVATION_PAGE_DATA_SUCCESS,
    SET_FULFILLMENT_DATA_SUCCESS,
    SET_STORE
} from '../action-types';

import {createReducer} from './reducer-factory';
import updateObject from './handler-functions/update-object';

const handlers = {
    [SET_FULFILLMENT_CHANGE_PAGE_DATA_SUCCESS]: updateObject('store'),
    [SET_FULFILLMENT_DATA_SUCCESS]: updateObject('store'),
    [SET_STORE]: updateObject('store'),
    [SET_TIME_RESERVATION_PAGE_DATA_SUCCESS]: updateObject('store')
};

export default createReducer({}, handlers);

export interface IStoreState {
    storeId: number;
    name: string;
    phone: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    latitude: number;
    longitude: number;
    legacyStoreId?: number;
    nickname: string;
}
