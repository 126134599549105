import {
    TOGGLE_SELECT_ALL_CART_ITEMS,
    TOGGLE_SELECT_ONE_GROCERY_CART_ITEM,
    REMOVE_CART_ITEMS,
    TOGGLE_SELECT_ONE_MTO_CART_ITEM
} from '../../action-types';
import {createReducer} from '../reducer-factory';

const toggleAllSelectedCartItems = (state, action) =>
    action.groceryCartItems.every(({cartItemId}) => state.selectedGroceryCartItemIds.includes(cartItemId)) &&
    action.madeToOrderCartItems.every(({cartItemId}) => state.selectedMTOCartItemIds.includes(cartItemId))
        ? {
              selectedGroceryCartItemIds: [],
              selectedMTOCartItemIds: []
          }
        : {
              selectedGroceryCartItemIds: action.groceryCartItems.map((cartItem) => cartItem.cartItemId),
              selectedMTOCartItemIds: action.madeToOrderCartItems.map((cartItem) => cartItem.cartItemId)
          };

const toggleSingleSelectedGroceryCartItem = (state, action) => {
    if (state.selectedGroceryCartItemIds.includes(action.cartItemId)) {
        return {
            ...state,
            selectedGroceryCartItemIds: state.selectedGroceryCartItemIds.filter(
                (cartItemId) => cartItemId !== action.cartItemId
            )
        };
    }

    return {
        ...state,
        selectedGroceryCartItemIds: [...state.selectedGroceryCartItemIds, action.cartItemId]
    };
};

const toggleSingleSelectedMTOCartItem = (state, action) => {
    if (state.selectedMTOCartItemIds.includes(action.cartItemId)) {
        return {
            ...state,
            selectedMTOCartItemIds: state.selectedMTOCartItemIds.filter(
                (cartItemId) => cartItemId !== action.cartItemId
            )
        };
    }

    return {
        ...state,
        selectedMTOCartItemIds: [...state.selectedMTOCartItemIds, action.cartItemId]
    };
};

const unselectRemovedItems = (state, action) => ({
    selectedGroceryCartItemIds: state.selectedGroceryCartItemIds.filter(
        (selectedCartItemId) => !action.groceryCartItemIds.includes(selectedCartItemId)
    ),
    selectedMTOCartItemIds: state.selectedMTOCartItemIds.filter(
        (selectedCartItemId) => !action.madeToOrderCartItemIds.includes(selectedCartItemId)
    )
});

const handlers = {
    [REMOVE_CART_ITEMS]: unselectRemovedItems,
    [TOGGLE_SELECT_ALL_CART_ITEMS]: toggleAllSelectedCartItems,
    [TOGGLE_SELECT_ONE_GROCERY_CART_ITEM]: toggleSingleSelectedGroceryCartItem,
    [TOGGLE_SELECT_ONE_MTO_CART_ITEM]: toggleSingleSelectedMTOCartItem
};

export default createReducer(
    {
        selectedGroceryCartItemIds: [],
        selectedMTOCartItemIds: []
    },
    handlers
);
