const updateArray = (state, index, updatedProperties) => [
    ...state.slice(0, index),
    {
        ...state[index],
        ...updatedProperties
    },
    ...state.slice(index + 1)
];

export default updateArray;
