import {
    SET_SAVE_NEW_DELIVERY_ADDRESS_FAILURE,
    SET_SAVE_NEW_DELIVERY_ADDRESS_REQUEST,
    SET_SAVE_NEW_DELIVERY_ADDRESS_SUCCESS,
    SET_STORE_SELECTION_DELIVERY_PAGE_DATA_FAILURE,
    SET_STORE_SELECTION_DELIVERY_PAGE_DATA_REQUEST,
    SET_STORE_SELECTION_DELIVERY_PAGE_DATA_SUCCESS,
    SET_STORE_SELECTION_PICKUP_PAGE_DATA_FAILURE,
    SET_STORE_SELECTION_PICKUP_PAGE_DATA_REQUEST,
    SET_STORE_SELECTION_PICKUP_PAGE_DATA_SUCCESS
} from '../../action-types';

import {startLoadingIndicator, stopLoadingIndicator} from './helpers/loading-functions';

const storeSelectionPageDataLoadingIndicators = {
    [SET_STORE_SELECTION_DELIVERY_PAGE_DATA_FAILURE]: stopLoadingIndicator(
        SET_STORE_SELECTION_DELIVERY_PAGE_DATA_REQUEST
    ),
    [SET_STORE_SELECTION_DELIVERY_PAGE_DATA_REQUEST]: startLoadingIndicator,
    [SET_STORE_SELECTION_DELIVERY_PAGE_DATA_SUCCESS]: stopLoadingIndicator(
        SET_STORE_SELECTION_DELIVERY_PAGE_DATA_REQUEST
    ),
    [SET_STORE_SELECTION_PICKUP_PAGE_DATA_FAILURE]: stopLoadingIndicator(SET_STORE_SELECTION_PICKUP_PAGE_DATA_REQUEST),
    [SET_STORE_SELECTION_PICKUP_PAGE_DATA_REQUEST]: startLoadingIndicator,
    [SET_STORE_SELECTION_PICKUP_PAGE_DATA_SUCCESS]: stopLoadingIndicator(SET_STORE_SELECTION_PICKUP_PAGE_DATA_REQUEST)
};

const storeSelectionSaveNewDeliveryAddressLoadingIndicators = {
    [SET_SAVE_NEW_DELIVERY_ADDRESS_FAILURE]: stopLoadingIndicator(SET_SAVE_NEW_DELIVERY_ADDRESS_REQUEST),
    [SET_SAVE_NEW_DELIVERY_ADDRESS_REQUEST]: startLoadingIndicator,
    [SET_SAVE_NEW_DELIVERY_ADDRESS_SUCCESS]: stopLoadingIndicator(SET_SAVE_NEW_DELIVERY_ADDRESS_REQUEST)
};

const storeSelectionLoadingIndicators = {
    ...storeSelectionPageDataLoadingIndicators,
    ...storeSelectionSaveNewDeliveryAddressLoadingIndicators
};

export default storeSelectionLoadingIndicators;
