import {combineReducers} from 'redux';

import addToListModalError from './add-to-list-modal-error-reducer';

const reducers = () =>
    combineReducers({
        addToListModalError
    });

export default reducers;
