import {combineReducers} from 'redux';

import fulfillmentTimeErrors from './fulfillment-time-errors-reducer';
import selectedTimeReservationTab from './selected-time-reservation-tab-reducer';
import timeReservationDays from './time-reservation-days-reducer';
import selectedTimeDurationTab from './selected-time-duration-tab-reducer';

const reducers = () =>
    combineReducers({
        fulfillmentTimeErrors,
        selectedTimeDurationTab,
        selectedTimeReservationTab,
        timeReservationDays
    });

export default reducers;
