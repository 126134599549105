import {
    SET_FULFILLMENT_CHANGE_PAGE_DATA_SUCCESS,
    SET_TIME_RESERVATION_PAGE_DATA_SUCCESS,
    SET_SAVE_NEW_DELIVERY_ADDRESS_SUCCESS,
    SET_SELECTED_DELIVERY_ADDRESS,
    SET_FULFILLMENT_DATA_SUCCESS
} from '../action-types';
import {DeliveryAddressLocationType} from '../../autogen/globalTypes';

import {createReducer} from './reducer-factory';
import updateObject from './handler-functions/update-object';

const handlers = {
    [SET_FULFILLMENT_CHANGE_PAGE_DATA_SUCCESS]: updateObject('deliveryAddress'),
    [SET_FULFILLMENT_DATA_SUCCESS]: updateObject('deliveryAddress'),
    [SET_SAVE_NEW_DELIVERY_ADDRESS_SUCCESS]: updateObject('deliveryAddress'),
    [SET_SELECTED_DELIVERY_ADDRESS]: updateObject('deliveryAddress'),
    [SET_TIME_RESERVATION_PAGE_DATA_SUCCESS]: updateObject('deliveryAddress')
};

export default createReducer({}, handlers);

export interface IDeliveryAddressState {
    deliveryAddressId: number;
    addressOne: string;
    addressTwo: string | null;
    firstName: string;
    lastName: string;
    latitude: string;
    longitude: string;
    locationType: DeliveryAddressLocationType;
    companyName: string | null;
    city: string;
    state: string;
    phoneNumber: string;
    zip: string;
}
