import {
    SET_ORDER_SUMMARY_AFTER_PURCHASE_PAGE_DATA_SUCCESS,
    SET_TIME_RESERVATION_PAGE_DATA_SUCCESS
} from '../action-types';

import {createReducer} from './reducer-factory';
import updateObject from './handler-functions/update-object';

const handlers = {
    [SET_ORDER_SUMMARY_AFTER_PURCHASE_PAGE_DATA_SUCCESS]: updateObject('customerHasAislesOnlineMembership'),
    [SET_TIME_RESERVATION_PAGE_DATA_SUCCESS]: updateObject('customerHasAislesOnlineMembership')
};

export default createReducer(null, handlers);
