import {SET_TIME_SLOT_SNATCHED_ERROR} from '../../action-types';
import {createReducer} from '../reducer-factory';

const addError = (state, action) => {
    return action.timeSlotSnatchedError;
};

const handlers = {
    [SET_TIME_SLOT_SNATCHED_ERROR]: addError
};

export default createReducer(false, handlers);
