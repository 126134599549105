import GoogleTagManagerWrapper from './views/components/authentication/google-tag-manager-wrapper';
import ErrorBoundary from './views/components/error-boundary';
import CustomerWrapper from './views/components/authentication/customer-wrapper';
import {IneligibleStoreAlertModal} from './views/components/ineligible-store-alert-modal';

const AislesOnline = (props) => (
    <GoogleTagManagerWrapper>
        <CustomerWrapper>
            <ErrorBoundary>
                <IneligibleStoreAlertModal />
                {props.children}
            </ErrorBoundary>
        </CustomerWrapper>
    </GoogleTagManagerWrapper>
);

export default AislesOnline;
