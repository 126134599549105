import {SET_SHOULD_DISPLAY_FULFILLMENT_INFO} from '../action-types';

import {createReducer} from './reducer-factory';
import updateObject from './handler-functions/update-object';

const handlers = {
    [SET_SHOULD_DISPLAY_FULFILLMENT_INFO]: updateObject('shouldDisplayFulfillmentInfo')
};

export default createReducer(false, handlers);
