import {
    SET_SAVE_NEW_DELIVERY_ADDRESS_SUCCESS,
    SET_STORE_SELECTION_DELIVERY_PAGE_DATA_SUCCESS
} from '../../action-types';
import {createReducer} from '../reducer-factory';
import updateObject from '../handler-functions/update-object';

const appendNewStoresToExistingStores = (state, action) => [...action.stores, ...state];

const handlers = {
    [SET_SAVE_NEW_DELIVERY_ADDRESS_SUCCESS]: appendNewStoresToExistingStores,
    [SET_STORE_SELECTION_DELIVERY_PAGE_DATA_SUCCESS]: updateObject('stores')
};

export default createReducer([], handlers);
