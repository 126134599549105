import {ADD_GIFT_CARD_SUCCESS, DELETE_GIFT_CARD_SUCCESS, SET_GIFT_CARDS_SUCCESS} from '../../action-types';
import {createReducer} from '../reducer-factory';
import updateObject from '../handler-functions/update-object';

const handlers = {
    [ADD_GIFT_CARD_SUCCESS]: updateObject('giftCards'),
    [DELETE_GIFT_CARD_SUCCESS]: updateObject('giftCards'),
    [SET_GIFT_CARDS_SUCCESS]: updateObject('giftCards')
};

export default createReducer([], handlers);
