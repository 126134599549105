import {combineReducers} from 'redux';

import carts from './carts-reducer';
import deliveryAddresses from './delivery-addresses-reducer';
import enteredDeliveryAddress from './entered-delivery-address-reducer';
import isMto from './is-mto-reducer';
import pickupLocations from './pickup-locations-reducer';
import saveAddressErrorStatus from './address-error-status-reducer';
import shouldDisplayNewAddressForm from './should-display-new-address-form-reducer';
import shouldShowAllMyAddresses from './should-show-all-my-addresses-reducer';
import shouldShowAllMyLocations from './should-show-all-my-locations-reducer';
import stores from './stores-reducer';
import storeSelectionTypeTab from './store-selection-type-tab-reducer';

const reducers = () =>
    combineReducers({
        carts,
        deliveryAddresses,
        enteredDeliveryAddress,
        isMto,
        pickupLocations,
        saveAddressErrorStatus,
        shouldDisplayNewAddressForm,
        shouldShowAllMyAddresses,
        shouldShowAllMyLocations,
        stores,
        storeSelectionTypeTab
    });

export default reducers;
