import {SET_LOGGING_IN_TO_WIC_VENDOR} from 'client/action-types';

import {createReducer} from './reducer-factory';
import updateObject from './handler-functions/update-object';

const handlers = {
    [SET_LOGGING_IN_TO_WIC_VENDOR]: updateObject('loggingInToWicVendor')
};

export default createReducer(false, handlers);
