import {
    SET_FULFILLMENT_CHANGE_PAGE_DATA_SUCCESS,
    SET_STORE_SELECTION_DELIVERY_PAGE_DATA_SUCCESS,
    SET_TIME_RESERVATION_PAGE_DATA_SUCCESS,
    SET_STORE_SELECTION_PICKUP_PAGE_DATA_SUCCESS,
    SET_ORDER_SUMMARY_AFTER_PURCHASE_PAGE_DATA_SUCCESS,
    SET_CUSTOMER
} from '../action-types';

import {createReducer} from './reducer-factory';
import updateObject from './handler-functions/update-object';

const handlers = {
    [SET_CUSTOMER]: updateObject('customer'),
    [SET_FULFILLMENT_CHANGE_PAGE_DATA_SUCCESS]: updateObject('customer'),
    [SET_ORDER_SUMMARY_AFTER_PURCHASE_PAGE_DATA_SUCCESS]: updateObject('customer'),
    [SET_STORE_SELECTION_DELIVERY_PAGE_DATA_SUCCESS]: updateObject('customer'),
    [SET_STORE_SELECTION_PICKUP_PAGE_DATA_SUCCESS]: updateObject('customer'),
    [SET_TIME_RESERVATION_PAGE_DATA_SUCCESS]: updateObject('customer')
};

export default createReducer({}, handlers);
