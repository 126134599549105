import {createReducer} from '../reducer-factory';
import {
    SET_STORE_SELECTION_DELIVERY_PAGE_DATA_SUCCESS,
    SET_STORE_SELECTION_PICKUP_PAGE_DATA_SUCCESS
} from '../../action-types';

const appendCarts = (state, action) => [...state, ...action.carts];

const handlers = {
    [SET_STORE_SELECTION_DELIVERY_PAGE_DATA_SUCCESS]: appendCarts,
    [SET_STORE_SELECTION_PICKUP_PAGE_DATA_SUCCESS]: appendCarts
};

export default createReducer([], handlers);
