import {SET_SELECTED_HIERARCHY} from '../../../action-types';
import {createReducer} from '../../reducer-factory';

const loadOpenFilterIntoState = (state, {hierarchy}) => {
    switch (Object.keys(hierarchy).join('')) {
        case '':
            return null;
        default:
            return hierarchy.departmentGroupId >= 0 ? hierarchy.departmentGroupId : state;
    }
};

const handlers = {
    [SET_SELECTED_HIERARCHY]: loadOpenFilterIntoState
};

export default createReducer(null, handlers);
