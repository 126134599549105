import {combineReducers} from 'redux';

import selectedCartItems from './selected-cart-items-reducer';
import cartPromotionFormError from './cart-promotion-form-error-reducer';

const reducers = () =>
    combineReducers({
        cartPromotionFormError,
        selectedCartItems
    });

export default reducers;
