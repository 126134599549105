import {CLEAR_ADDRESS_SUGGESTIONS, SET_ADDRESS_SUGGESTIONS_SUCCESS} from '../action-types';

import {createReducer} from './reducer-factory';
import updateObject from './handler-functions/update-object';

const handlers = {
    [CLEAR_ADDRESS_SUGGESTIONS]: updateObject('addressSuggestions'),
    [SET_ADDRESS_SUGGESTIONS_SUCCESS]: updateObject('addressSuggestions')
};

export default createReducer({}, handlers);
