import {useState, FC, PropsWithChildren} from 'react';
import {useDispatch} from 'react-redux';
import {Modal} from '@hy-vee/web-core';
import {Text, Button, SecondaryButton} from '@hy-vee/design-system';
import Cookie from 'js-cookie';
import {useQuery} from '@apollo/client';

import {setReservationStepperIndex, toggleActiveModal} from 'client/action-creators';
import {FULFILLMENT} from 'client/enums/reservation-stepper-enums';
import {getLocationEcommerceStatus} from 'client/graphql/queries/location-queries';
import {GetLocationEcommerceStatus, GetLocationEcommerceStatusVariables} from 'autogen/GetLocationEcommerceStatus';
import {LocationEcommerceStatus} from 'autogen/globalTypes';
import {useCustomerData} from 'client/hooks/customer-hooks';
import {AOH_IN_STORE_ONLY, AOH_IN_STORE_ONLY_POPUP} from 'client/enums/feature-toggle-names';
import {useFeatureToggle} from 'client/context/feature-toggle';

import styles from './ineligible-store-alert-modal.module.css';

export const IneligibleStoreAlertModal: FC<PropsWithChildren<PropsWithChildren>> = () => {
    const dispatch = useDispatch();
    const hasBeenShownIneligibleStoreWarning = Boolean(Cookie.get('hasBeenShownIneligibleStoreWarning'));
    const [isModalOpen, setIsModalOpen] = useState(!hasBeenShownIneligibleStoreWarning);
    const closeModal = () => setIsModalOpen(false);
    const launchReservationStepper = () => {
        closeModal();
        dispatch(toggleActiveModal('RESERVATION_STEPPER'));
        dispatch(setReservationStepperIndex(FULFILLMENT));
    };
    const {storeId} = useCustomerData();
    const {data} = useQuery<GetLocationEcommerceStatus, GetLocationEcommerceStatusVariables>(
        getLocationEcommerceStatus,
        {
            skip: hasBeenShownIneligibleStoreWarning,
            variables: {
                businessCode: storeId.toString()
            }
        }
    );
    const {featuresEnabled} = useFeatureToggle();
    const areInStoreOnlyFeatureTogglesEnabled = featuresEnabled([AOH_IN_STORE_ONLY, AOH_IN_STORE_ONLY_POPUP]);
    const isCustomerStoreInactive =
        data?.locationsByBusinessCode &&
        data.locationsByBusinessCode[0].ecommerceStatus === LocationEcommerceStatus.INACTIVE;

    if (isModalOpen && isCustomerStoreInactive && areInStoreOnlyFeatureTogglesEnabled) {
        Cookie.set('hasBeenShownIneligibleStoreWarning', 'true');

        return (
            <Modal data-testid="ineligible-location-notice" onClose={closeModal} size={'medium'}>
                <div className={styles.headerContainer}>
                    <Text textStyle={'heading4'}>{'Hy-Vee Aisles Online Notice'}</Text>
                </div>
                <Text textStyle={'body3'}>
                    {
                        'Starting Monday, March 28, we will be transitioning our Hy-Vee Aisles Online services to select Hy-Vee locations in your area. With this transition, online grocery delivery and pickup will no longer be available at this Hy-Vee location. Please select a different Hy-Vee store near you for these services.'
                    }
                </Text>
                <div className={styles.actionButtonContainer}>
                    <SecondaryButton onClick={launchReservationStepper}>{'Change Location'}</SecondaryButton>
                    <Button onClick={closeModal}>{'Keep Store'}</Button>
                </div>
            </Modal>
        );
    }

    return null;
};
