import {
    SET_FULFILLMENT_CHANGE_PAGE_DATA_SUCCESS,
    SET_FULFILLMENT_DATA_SUCCESS,
    SET_TIME_RESERVATION_PAGE_DATA_SUCCESS
} from '../action-types';
import {FulfillmentType} from '../../autogen/globalTypes';

import {createReducer} from './reducer-factory';
import updateObject from './handler-functions/update-object';

const handlers = {
    [SET_FULFILLMENT_CHANGE_PAGE_DATA_SUCCESS]: updateObject('pickupLocation'),
    [SET_FULFILLMENT_DATA_SUCCESS]: updateObject('pickupLocation'),
    [SET_TIME_RESERVATION_PAGE_DATA_SUCCESS]: updateObject('pickupLocation')
};

export default createReducer({}, handlers);

export interface IPickupLocationState {
    hasPasscode: boolean;
    pickupLocationId: number;
    fulfillmentLocationId: number;
    name: string;
    phoneNumber: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    latitude: string;
    longitude: string;
    fulfillmentStoreId: number;
    pickupStoreId: number;
    passcode: string;
    fulfillmentType: FulfillmentType.NOT_SELECTED | FulfillmentType.PICKUP | FulfillmentType.PICKUP_POINT;
    isActive: boolean;
}
