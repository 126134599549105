import {
    SET_FULFILLMENT_CHANGE_PAGE_DATA_SUCCESS,
    SET_TIME_RESERVATION_PAGE_DATA_SUCCESS,
    REMOVE_CART_ITEM_SUCCESS
} from '../action-types';
import {SubstitutionPreference} from '../../autogen/globalTypes';
import QuantityType from '../enums/quantity-types';

import {createReducer} from './reducer-factory';
import updateObject from './handler-functions/update-object';

const removeCartItem = (state, {cartItem}) => {
    const cartItemIndex = state.findIndex(({cartItemId}) => cartItemId === cartItem.cartItemId);

    return [...state.slice(0, cartItemIndex), ...state.slice(cartItemIndex + 1)];
};

const handlers = {
    [REMOVE_CART_ITEM_SUCCESS]: removeCartItem,
    [SET_FULFILLMENT_CHANGE_PAGE_DATA_SUCCESS]: updateObject('cartItems'),
    [SET_TIME_RESERVATION_PAGE_DATA_SUCCESS]: updateObject('cartItems')
};

export default createReducer([], handlers);

export interface ICartItemState {
    cartItemId: number;
    cartId: number;
    productId: number;
    storeProductId: number;
    note: string | null;
    quantity: number;
    quantityType: QuantityType;
    substitutionPreference: SubstitutionPreference;
    tax: number;
    userSelectedSubstitutionId: number | null;
}
