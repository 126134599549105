import {
    SET_CUSTOMER_CARDS_FAILURE,
    SET_CUSTOMER_CARDS_REQUEST,
    SET_CUSTOMER_CARDS_SUCCESS,
    DELETE_CARD_FAILURE,
    DELETE_CARD_REQUEST,
    DELETE_CARD_SUCCESS,
    SET_GIFT_CARDS_FAILURE,
    SET_GIFT_CARDS_REQUEST,
    SET_GIFT_CARDS_SUCCESS,
    ADD_GIFT_CARD_REQUEST,
    ADD_GIFT_CARD_SUCCESS,
    ADD_GIFT_CARD_FAILURE,
    EDIT_GIFT_CARD_NICKNAME_REQUEST,
    EDIT_GIFT_CARD_NICKNAME_SUCCESS,
    EDIT_GIFT_CARD_NICKNAME_FAILURE,
    DELETE_GIFT_CARD_REQUEST,
    DELETE_GIFT_CARD_SUCCESS,
    DELETE_GIFT_CARD_FAILURE,
    UPDATE_CUSTOMER_CARD_REQUEST,
    UPDATE_CUSTOMER_CARD_FAILURE,
    UPDATE_CUSTOMER_CARD_SUCCESS
} from '../../action-types';

import {startLoadingIndicator, stopLoadingIndicator} from './helpers/loading-functions';

const getCustomerCardsLoadingIndicators = {
    [SET_CUSTOMER_CARDS_FAILURE]: stopLoadingIndicator(SET_CUSTOMER_CARDS_REQUEST),
    [SET_CUSTOMER_CARDS_REQUEST]: startLoadingIndicator,
    [SET_CUSTOMER_CARDS_SUCCESS]: stopLoadingIndicator(SET_CUSTOMER_CARDS_REQUEST)
};

const deleteCardLoadingIndicators = {
    [DELETE_CARD_FAILURE]: stopLoadingIndicator(DELETE_CARD_REQUEST),
    [DELETE_CARD_REQUEST]: startLoadingIndicator,
    [DELETE_CARD_SUCCESS]: stopLoadingIndicator(DELETE_CARD_REQUEST)
};

const updateCustomerCardLoadingIndicators = {
    [UPDATE_CUSTOMER_CARD_FAILURE]: stopLoadingIndicator(UPDATE_CUSTOMER_CARD_REQUEST),
    [UPDATE_CUSTOMER_CARD_REQUEST]: startLoadingIndicator,
    [UPDATE_CUSTOMER_CARD_SUCCESS]: stopLoadingIndicator(UPDATE_CUSTOMER_CARD_REQUEST)
};

const getGiftCardsLoadingIndicators = {
    [SET_GIFT_CARDS_FAILURE]: stopLoadingIndicator(SET_GIFT_CARDS_REQUEST),
    [SET_GIFT_CARDS_REQUEST]: startLoadingIndicator,
    [SET_GIFT_CARDS_SUCCESS]: stopLoadingIndicator(SET_GIFT_CARDS_REQUEST)
};

const addGiftCardLoadingIndicators = {
    [ADD_GIFT_CARD_FAILURE]: stopLoadingIndicator(ADD_GIFT_CARD_REQUEST),
    [ADD_GIFT_CARD_REQUEST]: startLoadingIndicator,
    [ADD_GIFT_CARD_SUCCESS]: stopLoadingIndicator(ADD_GIFT_CARD_REQUEST)
};

const editGiftCardLoadingIndicators = {
    [EDIT_GIFT_CARD_NICKNAME_FAILURE]: stopLoadingIndicator(EDIT_GIFT_CARD_NICKNAME_REQUEST),
    [EDIT_GIFT_CARD_NICKNAME_REQUEST]: startLoadingIndicator,
    [EDIT_GIFT_CARD_NICKNAME_SUCCESS]: stopLoadingIndicator(EDIT_GIFT_CARD_NICKNAME_REQUEST)
};

const deleteGiftCardLoadingIndicators = {
    [DELETE_GIFT_CARD_FAILURE]: stopLoadingIndicator(DELETE_GIFT_CARD_REQUEST),
    [DELETE_GIFT_CARD_REQUEST]: startLoadingIndicator,
    [DELETE_GIFT_CARD_SUCCESS]: stopLoadingIndicator(DELETE_GIFT_CARD_REQUEST)
};

const paymentLoadingIndicators = {
    ...getCustomerCardsLoadingIndicators,
    ...deleteCardLoadingIndicators,
    ...updateCustomerCardLoadingIndicators,
    ...getGiftCardsLoadingIndicators,
    ...addGiftCardLoadingIndicators,
    ...editGiftCardLoadingIndicators,
    ...deleteGiftCardLoadingIndicators
};

export default paymentLoadingIndicators;
