import {SET_RESERVATION_STEPPER_INDEX} from '../action-types';

import {createReducer} from './reducer-factory';
import updateObject from './handler-functions/update-object';

const handlers = {
    [SET_RESERVATION_STEPPER_INDEX]: updateObject('reservationStepperIndex')
};

export default createReducer(null, handlers);
