import {
    ADD_GIFT_CARD_FAILURE,
    DELETE_GIFT_CARD_FAILURE,
    EDIT_GIFT_CARD_NICKNAME_FAILURE,
    SET_GIFT_CARD_ERROR
} from '../../action-types';
import updateObject from '../handler-functions/update-object';
import {createReducer} from '../reducer-factory';

const handlers = {
    [ADD_GIFT_CARD_FAILURE]: updateObject('giftCardError'),
    [DELETE_GIFT_CARD_FAILURE]: updateObject('giftCardError'),
    [EDIT_GIFT_CARD_NICKNAME_FAILURE]: updateObject('giftCardError'),
    [SET_GIFT_CARD_ERROR]: updateObject('giftCardError')
};

export default createReducer('', handlers);
