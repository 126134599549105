import {
    SET_ORDER_SUMMARY_AFTER_PURCHASE_PAGE_DATA_REQUEST,
    SET_ORDER_SUMMARY_AFTER_PURCHASE_PAGE_DATA_SUCCESS,
    SET_ORDER_SUMMARY_AFTER_PURCHASE_PAGE_DATA_FAILURE
} from '../../action-types';

import {startLoadingIndicator, stopLoadingIndicator} from './helpers/loading-functions';

const orderSummaryLoadingIndicators = {
    [SET_ORDER_SUMMARY_AFTER_PURCHASE_PAGE_DATA_FAILURE]: stopLoadingIndicator(
        SET_ORDER_SUMMARY_AFTER_PURCHASE_PAGE_DATA_REQUEST
    ),
    [SET_ORDER_SUMMARY_AFTER_PURCHASE_PAGE_DATA_REQUEST]: startLoadingIndicator,
    [SET_ORDER_SUMMARY_AFTER_PURCHASE_PAGE_DATA_SUCCESS]: stopLoadingIndicator(
        SET_ORDER_SUMMARY_AFTER_PURCHASE_PAGE_DATA_REQUEST
    )
};

export default orderSummaryLoadingIndicators;
