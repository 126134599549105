import {
    SET_TIME_RESERVATION_PAGE_DATA_FAILURE,
    SET_TIME_RESERVATION_PAGE_DATA_REQUEST,
    SET_TIME_RESERVATION_PAGE_DATA_SUCCESS
} from '../../action-types';

import {startLoadingIndicator, stopLoadingIndicator} from './helpers/loading-functions';

const timeReservationPageDataLoadingIndicators = {
    [SET_TIME_RESERVATION_PAGE_DATA_FAILURE]: stopLoadingIndicator(SET_TIME_RESERVATION_PAGE_DATA_REQUEST),
    [SET_TIME_RESERVATION_PAGE_DATA_REQUEST]: startLoadingIndicator,
    [SET_TIME_RESERVATION_PAGE_DATA_SUCCESS]: stopLoadingIndicator(SET_TIME_RESERVATION_PAGE_DATA_REQUEST)
};

export default timeReservationPageDataLoadingIndicators;
