import {Component, useMemo} from 'react';
import {Provider} from 'react-redux';

import AislesOnline from '../aisles-online';
import {isServerSide} from '../lib/env';
import {getInitialState} from '../action-creators';
import {createStore} from '../services/redux-store-service';

const __NEXT_REDUX_STORE__ = '__NEXT_REDUX_STORE__';

export const getOrCreateStore = (initialState) => {
    if (isServerSide()) {
        return createStore(initialState);
    }

    if (!window[__NEXT_REDUX_STORE__]) {
        window[__NEXT_REDUX_STORE__] = createStore(initialState);
    }

    return window[__NEXT_REDUX_STORE__];
};

const withRedux = (WrappedComponent) => {
    class WithRedux extends Component {
        static async getInitialProps(ctx) {
            const shouldGetInitialState = isServerSide() || !window[__NEXT_REDUX_STORE__];

            const reduxStore = getOrCreateStore();

            ctx.reduxStore = reduxStore; // eslint-disable-line no-param-reassign

            if (shouldGetInitialState && ctx.req) {
                await getInitialState(ctx.req)(reduxStore.dispatch);
            }

            let pageProps = {};

            if (WrappedComponent.getInitialProps) {
                pageProps = await WrappedComponent.getInitialProps(ctx);
            }

            return {
                ...pageProps,
                reduxState: ctx.reduxStore.getState()
            };
        }

        constructor(props) {
            super(props);

            this.reduxStore = getOrCreateStore(props.reduxState);
        }

        render() {
            return (
                <Provider store={this.reduxStore}>
                    <AislesOnline reduxStore={this.reduxStore}>
                        <WrappedComponent {...this.props} />
                    </AislesOnline>
                </Provider>
            );
        }
    }

    return WithRedux;
};

export const withReduxWrapper = (WrappedComponent) => {
    const WithReduxWapper = (props) => {
        const reduxStore = useMemo(() => getOrCreateStore(props.reduxState), [props.reduxState]);

        return (
            <Provider store={reduxStore}>
                <AislesOnline reduxStore={reduxStore}>
                    <WrappedComponent {...props} />
                </AislesOnline>
            </Provider>
        );
    };

    return WithReduxWapper;
};

export default withRedux;
