import {useEffect} from 'react';
import {useQuery} from '@apollo/client';
import {useSelector, useDispatch} from 'react-redux';
import {setCustomer} from '@hy-vee/monitoring';

import {setCustomerAndStoreData} from '../../../action-creators';
import {getCustomerAndCartStoreData} from '../../../graphql/queries/customer-and-feature-queries';
import {useAislesOnlineRouter} from '../../../hooks/use-aisles-online-router';
import {useInactiveCartItemCheck} from '../../../hooks/use-inactive-cart-item';

const EXCLUDED_ROUTES = ['/aisles-online', '/aisles-online/culinary/recipe-add-to-cart', '/aisles-online/search'];

const CustomerWrapper = ({children}) => {
    useInactiveCartItemCheck();
    const router = useAislesOnlineRouter();
    const isExcludedRoute = EXCLUDED_ROUTES.includes(router.pathnameWithBasePath);

    const customer = useSelector((state) => state.customer);
    const isAuthenticated = useSelector((state) => state.isAuthenticated);

    const dispatch = useDispatch();
    const customerAndStoreData = useQuery(getCustomerAndCartStoreData, {
        skip: !isAuthenticated || !customer || isExcludedRoute,
        variables: {
            customerId: customer?.customerId
        }
    });

    useEffect(() => {
        if (
            isAuthenticated &&
            customer &&
            customerAndStoreData.data &&
            !customerAndStoreData.loading &&
            !customerAndStoreData.error
        ) {
            setCustomer({customerUuid: customer.customerUuid});
            dispatch(
                setCustomerAndStoreData({
                    customer: customerAndStoreData.data.customer,
                    store: customerAndStoreData.data.carts[0]?.store || {}
                })
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerAndStoreData]);

    if (!isAuthenticated) {
        return children;
    }

    return !customerAndStoreData.loading ? children : null;
};

export default CustomerWrapper;
