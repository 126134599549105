import {PLACE_ORDER_FAILURE, SET_ORDER_IN_PROGRESS} from '../action-types';

import {createReducer} from './reducer-factory';
import updateObject from './handler-functions/update-object';

const handlers = {
    [PLACE_ORDER_FAILURE]: updateObject('orderInProgress'),
    [SET_ORDER_IN_PROGRESS]: updateObject('orderInProgress')
};

export default createReducer(false, handlers);
