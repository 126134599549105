import {SET_PAYMENT_CHECKOUT_VIEW} from '../../action-types';
import {createReducer} from '../reducer-factory';
import updateObject from '../handler-functions/update-object';
import {SAVED_CARDS} from '../../enums/checkout-payment-views';

const handlers = {
    [SET_PAYMENT_CHECKOUT_VIEW]: updateObject('paymentCheckoutView')
};

export default createReducer(SAVED_CARDS, handlers);
