import {combineReducers} from 'redux';

import selectedSpdOptions from './selected-spd-options-reducer';
import selectedHierarchy from './selected-hierarchy-reducer';
import openedCategoryFilter from './opened-filters/opened-category-reducer';
import openedDepartmentFilter from './opened-filters/opened-department-reducer';
import openedDepartmentGroupFilter from './opened-filters/opened-department-group-reducer';

const reducers = () =>
    combineReducers({
        openedFilters: combineReducers({
            openedCategoryFilter,
            openedDepartmentFilter,
            openedDepartmentGroupFilter
        }),
        selectedHierarchy,
        selectedSpdOptions
    });

export default reducers;
