import {
    SET_SAVE_NEW_DELIVERY_ADDRESS_SUCCESS,
    SET_STORE_SELECTION_DELIVERY_PAGE_DATA_SUCCESS
} from '../../action-types';
import {createReducer} from '../reducer-factory';
import updateObject from '../handler-functions/update-object';

const appendSavedAddressToExistingDeliveryAddresses = (state, action) => [action.deliveryAddress, ...state];

const handlers = {
    [SET_SAVE_NEW_DELIVERY_ADDRESS_SUCCESS]: appendSavedAddressToExistingDeliveryAddresses,
    [SET_STORE_SELECTION_DELIVERY_PAGE_DATA_SUCCESS]: updateObject('deliveryAddresses')
};

export default createReducer([], handlers);
