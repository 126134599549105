import {combineReducers} from 'redux';

import {FulfillmentType} from '../../../autogen/globalTypes';
import {ICartItemState} from '../cart-items-reducer';
import {IDeliveryAddressState} from '../delivery-address-reducer';
import {IPickupLocationState} from '../pickup-location-reducer';
import {IStoreState} from '../store-reducer';

import formSubmitted from './form/form-submitted-reducer';
import formSuccessfullySubmitted from './form/form-successfully-submitted-reducer';
import newFulfillmentType from './new-fulfillment-type-reducer';
import newStore from './new-store-reducer';
import passcodeValue from './form/inputs/passcode/passcode-value-reducer';
import passcodeValidationErrorMessage from './form/inputs/passcode/passcode-validation-error-message-reducer';
import newPickupLocation from './new-pickup-location-reducer';
import newDeliveryAddress from './new-delivery-address-reducer';
import removedCartItems from './removed-cart-items-reducer';
import transitionDate from './transition-date-reducer';

const reducers = () =>
    combineReducers({
        form: combineReducers({
            inputs: combineReducers({
                passcode: combineReducers({
                    validationErrorMessage: passcodeValidationErrorMessage,
                    value: passcodeValue
                })
            }),
            submitted: formSubmitted,
            successfullySubmitted: formSuccessfullySubmitted
        }),
        newDeliveryAddress,
        newFulfillmentType,
        newPickupLocation,
        newStore,
        removedCartItems,
        transitionDate
    });

export interface IFulfillmentChangeState {
    form: {
        inputs: {
            passcode: {
                validationErrorMessage: string;
                value: string;
            };
        };
        submitted: boolean;
        successfullySubmitted: boolean;
    };
    removedCartItems: {
        cartItem: ICartItemState;
        reason: string;
    }[];
    newDeliveryAddress: IDeliveryAddressState;
    newFulfillmentType: FulfillmentType;
    newPickupLocation: IPickupLocationState;
    newStore: IStoreState;
    transitionDate: string | null;
}

export default reducers;
