import {getDistance, convertDistance} from 'geolib';

import {
    SET_PICKUP_LOCATIONS_DISTANCE,
    SET_STORE_SELECTION_PICKUP_PAGE_DATA_SUCCESS,
    CLEAR_FILTERED_PICKUP_STORES
} from '../../action-types';
import {createReducer} from '../reducer-factory';
import updateObject from '../handler-functions/update-object';

const clearMilesAway = (state) => {
    return state.map((pickupLocation) => ({
        ...pickupLocation,
        milesAway: null
    }));
};

const addMilesAway = (state, action) => {
    const latitude = action.latitude;
    const longitude = action.longitude;

    const distance = (lat, long) =>
        getDistance(
            {
                latitude: lat,
                longitude: long
            },
            {
                latitude,
                longitude
            }
        );

    return state.map((pickupLocation) => ({
        ...pickupLocation,
        milesAway: convertDistance(distance(pickupLocation.latitude, pickupLocation.longitude), 'mi').toFixed(1)
    }));
};

const handlers = {
    [CLEAR_FILTERED_PICKUP_STORES]: clearMilesAway,
    [SET_PICKUP_LOCATIONS_DISTANCE]: addMilesAway,
    [SET_STORE_SELECTION_PICKUP_PAGE_DATA_SUCCESS]: updateObject('pickupLocations')
};

export default createReducer([], handlers);
