import {combineReducers} from 'redux';

import addressSuggestions from './address-suggestions-reducer';
import activeModal from './active-modal-reducer';
import apiCallErrorMessage from './api-call-error-message-reducer';
import cart from './cart-reducer';
import cartItems from './cart-items-reducer';
import checkoutSubstitutions from './checkout-substitutions';
import customer from './customer-reducer';
import customerHasAislesOnlineMembership from './customer-has-aisles-online-membership-reducer';
import deliveryAddress from './delivery-address-reducer';
import groceryDeliveryTip from './grocery-delivery-tip';
import fulfillmentChange from './fulfillment-change';
import fulfillmentFees from './fulfillment-fees-reducer';
import fulfillmentLocation from './fulfillment-location-reducer';
import fulfillmentTimes from './fulfillment-times-reducer';
import isAuthenticated from './is-authenticated-reducer';
import timeReservation from './time-reservation';
import storeSelection from './store-selection';
import loadingIndicators from './loading-indicators';
import mtoOrderValidated from './mto-order-validated-reducer';
import order from './order-reducer';
import orderInProgress from './order-in-progress-reducer';
import orderItems from './order-items-reducer';
import paymentMethods from './payment-methods';
import paypal from './paypal';
import pickupLocation from './pickup-location-reducer';
import placeOrderErrorStatus from './place-order-error-status-reducer';
import products from './products-reducer';
import promotions from './promotions-reducer';
import orderPromotions from './order-promotions-reducer';
import shouldDisplayFulfillmentInfo from './should-display-fulfillment-info-reducer';
import storeProducts from './store-products-reducer';
import store from './store-reducer';
import productSearch from './product-search';
import cartPage from './cart-page';
import lists from './lists';
import timeSlotSnatchedError from './time-reservation/set-time-slot-snatched-error-reducer';
import citrusBanners from './citrus-banners';
import reservationStepperIndex from './reservation-stepper-index-reducer';
import loggingInToWicVendor from './logging-in-to-wic-vendor-reducer';
import selectedCardId from './card-details/select-card-reducer';

const reducers = () =>
    combineReducers({
        activeModal,
        addressSuggestions,
        apiCallErrorMessage,
        cart,
        cartItems,
        cartPage: cartPage(),
        checkoutSubstitutions: checkoutSubstitutions(),
        citrusBanners: citrusBanners(),
        customer,
        customerHasAislesOnlineMembership,
        deliveryAddress,
        fulfillmentChange: fulfillmentChange(),
        fulfillmentFees,
        fulfillmentLocation,
        fulfillmentTimes,
        groceryDeliveryTip: groceryDeliveryTip(),
        isAuthenticated,
        lists: lists(),
        loadingIndicators,
        loggingInToWicVendor,
        mtoOrderValidated,
        order,
        orderInProgress,
        orderItems,
        orderPromotions,
        paymentMethods: paymentMethods(),
        paypal: paypal(),
        pickupLocation,
        placeOrderErrorStatus,
        products,
        productSearch: productSearch(),
        promotions,
        reservationStepperIndex,
        selectedCardId,
        shouldDisplayFulfillmentInfo,
        store,
        storeProducts,
        storeSelection: storeSelection(),
        timeReservation: timeReservation(),
        timeSlotSnatchedError
    });

export default reducers;
