import {SET_STORE_SELECTION_TYPE_TAB} from '../../action-types';
import {DEFAULT_VIEW} from '../../enums/store-selection-tab-types';
import {createReducer} from '../reducer-factory';
import updateObject from '../handler-functions/update-object';

const handlers = {
    [SET_STORE_SELECTION_TYPE_TAB]: updateObject('storeSelectionTypeTab')
};

export default createReducer(DEFAULT_VIEW, handlers);
