import updateArray from '../../handler-functions/update-array';

export const startLoadingIndicator = (state, action) => {
    const loadingIndicatorIndex = state.findIndex(
        (loadingIndicatorFromState) => loadingIndicatorFromState.name === action.type
    );

    if (loadingIndicatorIndex === -1) {
        return state.concat({
            name: action.type,
            requestsCompleted: 0,
            requestsStarted: 1
        });
    }

    return updateArray(state, loadingIndicatorIndex, {
        requestsStarted: state[loadingIndicatorIndex].requestsStarted + 1
    });
};

export const stopLoadingIndicator = (correspondingActionType) => (state) => {
    const loadingIndicatorIndex = state.findIndex(
        (loadingIndicatorFromState) => loadingIndicatorFromState.name === correspondingActionType
    );

    return updateArray(state, loadingIndicatorIndex, {
        requestsCompleted: state[loadingIndicatorIndex].requestsCompleted + 1
    });
};
