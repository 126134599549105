import {FORBIDDEN, UNAUTHORIZED} from 'http-status';

import {
    ACKNOWLEDGE_API_CALL_FAILURE,
    SET_FULFILLMENT_CHANGE_PAGE_DATA_FAILURE,
    SET_TIME_RESERVATION_PAGE_DATA_FAILURE,
    SET_TIME_RESERVATION_PAGE_DATA_SUCCESS,
    UPDATE_CART_LOCATION_FAILURE,
    DELETE_CARD_FAILURE
} from '../action-types';

import {createReducer} from './reducer-factory';

const errorMessage = 'An error occurred. Please try again.';

const displayError = (state, action) => {
    if (
        action &&
        action.response &&
        (action.response.status === UNAUTHORIZED || action.response.status === FORBIDDEN)
    ) {
        return [];
    }

    return [errorMessage];
};

const acknowledgeError = () => [];

const handlers = {
    [ACKNOWLEDGE_API_CALL_FAILURE]: acknowledgeError,
    [DELETE_CARD_FAILURE]: displayError,
    [SET_FULFILLMENT_CHANGE_PAGE_DATA_FAILURE]: displayError,
    [SET_TIME_RESERVATION_PAGE_DATA_FAILURE]: displayError,
    [SET_TIME_RESERVATION_PAGE_DATA_SUCCESS]: acknowledgeError,
    [UPDATE_CART_LOCATION_FAILURE]: displayError
};

export default createReducer([], handlers);
