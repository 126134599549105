import {combineReducers} from 'redux';

import deliveryTip from './delivery-tip-reducer';
import tipStage from './delivery-tip-stage-reducer';

const reducers = () =>
    combineReducers({
        deliveryTip,
        tipStage
    });

export default reducers;
