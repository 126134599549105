import queryString from 'query-string';

import {SET_SELECTED_SPD_OPTIONS} from '../../action-types';
import {createReducer} from '../reducer-factory';
import {replacePropertyOnLocationSearch} from '../../utils/view-helpers/view-helpers';
import {isServerSide} from '../../lib/env';

const searchOptions = () => {
    if (isServerSide()) {
        return [];
    }

    return queryString.parse(window.location.search, {arrayFormat: 'comma'}).options || [];
};

const initialSpdFilters = [].concat(searchOptions());

const setSpdFilters = (state, {optionIdsArray, value}) => {
    const difference = state.filter((option) => !optionIdsArray.includes(option));

    return value ? [...state, ...optionIdsArray] : difference;
};

const loadHierarchyIntoStateAndSetQueryParams = (state, action) => {
    const spdFilters = setSpdFilters(state, action);

    replacePropertyOnLocationSearch(spdFilters, 'options');

    return spdFilters;
};

const handlers = {
    [SET_SELECTED_SPD_OPTIONS]: loadHierarchyIntoStateAndSetQueryParams
};

export default createReducer(initialSpdFilters, handlers);
