import {SET_CUSTOMER_CARDS_SUCCESS, SET_CUSTOMER_CARDS, UPDATE_CUSTOMER_CARD_SUCCESS} from '../../action-types';
import {createReducer} from '../reducer-factory';
import updateObject from '../handler-functions/update-object';

const handlers = {
    [SET_CUSTOMER_CARDS]: updateObject('paymentMethods'),
    [SET_CUSTOMER_CARDS_SUCCESS]: updateObject('paymentMethods'),
    [UPDATE_CUSTOMER_CARD_SUCCESS]: updateObject('paymentMethods')
};

export default createReducer([], handlers);
