import {createReducer} from '../reducer-factory';

import fulfillmentChangeLoadingIndicators from './fulfillment-change-loading-indicators';
import orderSummaryLoadingIndicators from './order-summary-loading-indicators';
import timeReservationPageDataLoadingIndicators from './time-reservation-loading-indicators';
import storeSelectionLoadingIndicators from './store-selection-loading-indicators';
import paymentLoadingIndicators from './payment-loading-indicators';

const handlers = {
    ...fulfillmentChangeLoadingIndicators,
    ...orderSummaryLoadingIndicators,
    ...timeReservationPageDataLoadingIndicators,
    ...storeSelectionLoadingIndicators,
    ...paymentLoadingIndicators
};

export default createReducer([], handlers);
