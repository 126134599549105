import {SET_GROCERY_DELIVERY_TIP_STAGE} from '../../action-types';
import {createReducer} from '../reducer-factory';
import updateObject from '../handler-functions/update-object';
import {CONFIRMED} from '../../enums/grocery-delivery-tip-stages';

const handlers = {
    [SET_GROCERY_DELIVERY_TIP_STAGE]: updateObject('tipStage')
};

export default createReducer(CONFIRMED, handlers);
