import {combineReducers} from 'redux';

import cartItemsWithNoSubsAvailable from './no-subs-available-reducer';
import error from './substitutions-error-reducer';

const reducers = () =>
    combineReducers({
        cartItemsWithNoSubsAvailable,
        error
    });

export default reducers;
